/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const ArrowheadRightIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M7.4 22c-.5 0-.9-.4-.8-.9 0-.2.1-.4.2-.6l8.6-8.6-8.5-8.4c-.3-.4-.4-.9 0-1.2.3-.4.8-.4 1.2-.1l.1.1 9.1 9.1c.3.3.3.9 0 1.2L8 21.7c-.2.2-.4.3-.6.3z" />
	</svg>
))
ArrowheadRightIconSvg.displayName = 'ArrowheadRightIconSvg'

const ArrowheadRightIcon = forwardRef((props, ref) => <Icon component={ArrowheadRightIconSvg} ref={ref} {...props} />)
ArrowheadRightIcon.displayName = 'ArrowheadRightIcon'

ArrowheadRightIcon.defaultProps = {
	...Icon.defaultProps,
}
ArrowheadRightIcon.propTypes = {
	...Icon.propTypes,
}

export default ArrowheadRightIcon
export { ArrowheadRightIconSvg }
