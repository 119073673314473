/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const ArrowheadUpIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M22 16.6c0 .5-.4.9-.9.8-.2 0-.4-.1-.6-.2l-8.6-8.6-8.5 8.5c-.3.4-.8.4-1.2.1-.4-.3-.4-.8-.1-1.2l.1-.1 9.1-9.1c.3-.3.9-.3 1.2 0l9.2 9.2c.2.2.3.4.3.6z" />
	</svg>
))
ArrowheadUpIconSvg.displayName = 'ArrowheadUpIconSvg'

const ArrowheadUpIcon = forwardRef((props, ref) => <Icon component={ArrowheadUpIconSvg} ref={ref} {...props} />)
ArrowheadUpIcon.displayName = 'ArrowheadUpIcon'

ArrowheadUpIcon.defaultProps = {
	...Icon.defaultProps,
}
ArrowheadUpIcon.propTypes = {
	...Icon.propTypes,
}

export default ArrowheadUpIcon
export { ArrowheadUpIconSvg }
